import React from 'react';
import { Link } from 'gatsby';


import { StaticImage } from "gatsby-plugin-image"


import Layout from '../components/layout/Layoutexport-en';

import Button from '../components/Button';

import SplitSection from '../components/SplitSection';





import HeroBanner from "../components/HeroExport-en"

const seo={
  title: "Unilimpio - Professional Hygiene products and solutions for export.",
  desc:  "We manufacture and export top wuality products for the latin american region and beyond.",
  image: "productos.jpg",
  pathname: "/export-en/",
  pageLang: "en",

}

const siteVerOpts = [

  {
    label: 'International',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/',

  },


];

const langOpts = [

  {
    label: 'EN',
    value: '',

  },


  {
    label: 'ES',
    value: '/export-es/',

  },


];

const Export = ({  location }) => {




  return(

<Layout location={location} crumbLabel="Export Home"
siteVerOpts={siteVerOpts} langOpts={langOpts}
alternates
pageLang={seo.pageLang}>



<HeroBanner />

<SplitSection id="salud y bienestar"
      reverseOrder className="bg-gray-300 px-4 pt-12 pb-20"
      primarySlot={
        <div className="  my-auto  px-2 mx-6 ">
          <h2 className="mt-1.5 text-5xl font-semibold  text-left text-primary-lighter pt-20">
          We are Health and Wellness
          </h2>
          <p className="mt-1.5 text-2xl font-normal leading-relaxed text-left text-primary-default ">
          Your health and that of your customers is important to us, which is why we offer you the best professional hygiene products for proper surface and hand disinfection. 
          </p>
          <div className="flex flex-wrap  justify-left">
          <Link to="/export/en/about-us/" className="mt-4 text-lime-500 inline-flex items-center text-xl text-bold">About our company
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>

        </div>
      }
      secondarySlot={
       
       <StaticImage src="../images/unilimpio-es-salud-y-bienestar.jpg"
                      className=" flex justify-center "
                      alt="Productos de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"
                       />
}
/>



<section id="Productos de exportacion" className="">
  <div className="relative bg-blue-900">
   <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
    <StaticImage src="../images/productos-de-exportacion-de-higiene-profesional.jpg"
                      className=" object-cover"
                      alt="Productos de higiene de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"/>
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-4  text-5xl font-semibold text-white text-left">Professional Hygiene Products</h2>
      <p className="mt-6 text-2xl text-left font-normal text-white">We manufacture and export to the entire Central and South American region hygienic products of the highest quality to satisfy the most demanding markets and industries.</p>
    <div className="mt-8">
      <div className="flex flex-wrap mx-auto justify-left pt-4">
         <Link to="/export/en/products/">
          <Button className=" text-primary-default text-bold text-base  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">See products</Button>
         </Link>
      </div>
      </div>
    </div>
  </div>
 </div>
</section>


<section id="value-proposition" className="bg-primary-lighter" aria-labelledby="contact-heading">
      <div className="w-full mx-auto   max-w-7xl pb-20 pt-4 px-4 sm:px-6 lg:px-8">
        <div className=" my-24">
          <h2 className="text-white text-center font-semibold text-5xl">- Our Value Proposition -</h2>
        </div>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-6">
          <div className="flex flex-col bg-gray-100 rounded shadow-xl">
            <div className="flex-1 relative pt-14 px-4 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-lime-500 rounded-xl shadow-lg transform -translate-y-1/2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>

              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center ">World-class quality</h3>
              <p className="mt-4 text-lg text-primary-default">We are certified manufacturers with more than 25 years of experience. All our products are manufactured under Good Manufacturing Practices processes.</p>
            </div>
          </div>

          <div className="flex flex-col bg-white rounded shadow-xl bg-gray-100">
            <div className="flex-1 relative pt-16 px-4 pb-8 md:px-8 pt-14">
              <div className="absolute top-0 p-5 inline-block bg-lime-500 rounded-xl shadow-lg transform -translate-y-1/2 ">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center ">Promximity</h3>
              <p className="mt-4 text-lg text-primary-default">We are located in the center of the world, and have the capacity to supply our products to the entire region, with a lead time up to 3 times less than that required to bring goods from Asia.</p>
            </div>
          </div>

          <div className="flex flex-col bg-white rounded shadow-xl bg-gray-100">
            <div className="flex-1 relative pt-16 px-4 pb-8 md:px-8 pt-14">
              <div className="absolute top-0 p-5 inline-block bg-lime-500 rounded-xl shadow-lg transform -translate-y-1/2">

              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" /></svg>
              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center">Credit</h3>
              <p className="mt-4 text-lg text-primary-default">We have financing mechanisms to grant credit to our international customers from their first purchase.</p>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded shadow-xl bg-gray-100">
            <div className="flex-1 relative pt-16 px-4 pb-8 md:px-8 pt-14">
              <div className="absolute top-0 p-5 inline-block bg-lime-500 rounded-xl shadow-lg transform -translate-y-1/2">

              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
              </div>
              <h3 className="text-3xl font-semibold text-primary-lighter text-center">Support and accompaniment</h3>
              <p className="mt-4 text-lg text-primary-default">We support our clients by training their sales force, advising and accompanying them in the definition and implementation of commercial strategies.</p>
            </div>
          </div>

        </div>
      </div>
    </section>


    <section id="sellos de calidad" className="bg-blue-900 py-16">
  <div className="container mx-auto sm:flex ">
    <div className=" w-full sm:flex-1 ">
      <h2 className="  pl-5 mx-auto text-3xl font-semibold lg:text-left sm:text-center text-white pb-4 pt-10">
      We comply with national and international certifications and are committed to responsible forest management:
      </h2>
   </div>
    <div className="w-full sm:flex-1 ">
     <div className="grid grid-cols-4 gap-4  pl-10 pr-10">
     <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/logo-iso.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con la certificación ISO"
                    placeholder="blurred"
                    layout="constrained"/>
        </div> 
       <div class="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/logo-issa.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con la certificación ISSA"
                    placeholder="blurred"
                    layout="constrained"/>
      </div>
        
        <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/sello-fsc.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Somos certificados FSC para nuestros absorbentes"
                    placeholder="blurred"
                    layout="constrained"/>
        </div>            
        <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/sello-bpm.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con el sello de buenas prácticas de manufactura"
                    placeholder="blurred"
                    layout="constrained"/>
        </div> 
        <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/sello-mucho-mejor-ecuador.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con el sello de Mucho Mejor Ecuador"
                    placeholder="blurred"
                    layout="constrained"/>
        </div> 
        <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/logo-ceres.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Somos miembro de CERES"
                    placeholder="blurred"
                    layout="constrained"/>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="Responsabilidad Social" className="">
<div className="relative bg-lime-500">
  <div className="h-56 bg-secondary-lighter sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/somos-sostenibles.jpg"
                      className=" flex justify-center"
                      alt="Unilimpio es una empresa ecuatoriana sostenible"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-6  sm:px-6 lg:px-8 lg:py-16 pt-40">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-6  text-5xl font-semibold text-white text-left">We are Sustainable</h2>
      <p className="mt-8 text-2xl text-left font-normal text-white">We are a responsible, transparent and supportive company. Our company has been recognized locally for its commitment to corporate social responsibility. <br/> <br/> We are part of the United Nations Global Compact. </p>
      <div className="mt-14">
      <div className="flex flex-wrap mx-auto text-left">
          <Link to="/export/en/social-responsability" className="mt-2 text-primary-default inline-flex items-center text-lg text-bold">Learn about our RS
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
</section>


<section id="nuestros clientes" className=" ">
<div className="bg-primary-lighter py-10">
  <div className="max-w-7xl mx-auto py-18 px-4 sm:px-6 lg:py-16 lg:px-8">
    <p className="text-center  text-5xl font-semibold text-white">
    Our main partners in Ecuador
    </p><br/>
    <div className="mt-6 grid grid-cols-2 gap-7 md:grid-cols-7 lg:mt-8">
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-kfc.jpg"
                    className=" rounded-full my-4"
                    alt="Logo de nuestra empresa aliada KFC"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-br.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Baskin Robbins"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-cf.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Corporación Favorita"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-ci.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Clinica Internacional"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-jv.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Juan Valdez"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-hanaska.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Hanaska"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div class="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-cn.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Cervecería Nacional"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-decameron.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Decameron"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-pn.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Pronaca"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-aeropuerto-quito.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Aeropuerto de Quito"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-pharmacys.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Pharmacys"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-bc.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Big Cola"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-hi.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Holiday Inn"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
      <div className="col-span-1 flex justify-center  rounded-full">
      <StaticImage src="../images/logo-aeropuerto-guayaquil.jpg"
                    className="rounded-full my-4"
                    alt="Logo de nuestra empresa aliada Aeropuerto de Guayaquil"
                    placeholder="blurred"
                    layout="constrained" />
      </div>
    </div>
  </div>
</div>
</section>


<section id="contacto" className="">
<div className="relative bg-lime-500">
  <div className="h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/call-center-unilimpio-export.jpg"
                      className=" object-cover"
                      alt="Solicite una cotización con Unilimpio"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-20">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-4  text-4xl font-bold text-white text-center">¡REQUEST A QUOTE NOW!</h2>
      <p className="mt-6 text-2xl text-left font-normal text-white">Thank you for your interest in Unilimpio S.A. We will be pleased to provide you with more information about our professional hygiene products.</p>
      <div className="mt-6">
      <div className="flex flex-wrap mx-auto justify-center pt-4">
         <Link to="/export/en/contact/">
          <Button className=" text-primary-lighter text-semibold text-xl  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Quote</Button>
         </Link>
       </div>
      </div>
    </div>
  </div>
</div>
</section>



  </Layout>

)

}




export default Export

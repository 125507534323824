import React from 'react';

import { Link } from 'gatsby';

import Button from './Button';



const HeroBanner = () => (


<section id="hero-banner" className={`bg-hero-banner-11 bg-fill bg-right-bottom bg-no-repeat m-0 p-0`}>
        <div className="container mt-0 mx-auto ">
          <div className="">
            <div className="">

              <div className=" bg-white bg-opacity-50 text-center  p-8 md:text-left md:w-2/5 md:pr-12 py-10">
                <h1 className="text-5xl  font-bold  text-center text-primary-lighter">
                WE CARE AND PROTECT YOU
                </h1>
                <p className="text-2xl mt-6 font-normal text-left text-primary-default">
                With us you can create safe environments for your customers and employees with professional hygiene products.
                </p>
                <div className="flex flex-wrap mx-auto justify-center mt-8 md:mt-12 mb-20">

                <Link to="/export/en/products/">
                  <Button className=" bg-primary-default text-white hover:bg-primary-lighter rounded-lg active:bg-primary-darker focus:outline-none" size="lg">Discover</Button>
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
  </section>

);


export default HeroBanner
